<template>
  <Card>
    <template #content>
      <div class="formgrid grid">
        <div class="field col-6 md:col-6">
          <Button
            label="Voltar"
            icon="pi pi-chevron-left"
            class="mt-2 mr-2 p-button-sm p-button-secondary"
            @click="$router.back()" />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-6 md:col-6">
          <label for="rubrica">Rubrica</label>
          <InputText id="rubrica" v-model="consignacao.rubrica.nome" disabled />
        </div>
        <div class="field col-6 md:col-6">
          <label for="rubrica">Nº Contrato</label>
          <InputText
            id="rubrica"
            v-model="consignacao.numeroContrato"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-3 md:col-3">
          <label for="valor">Valor da Parcela</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">R$</span>
            <InputText id="valor" v-model="consignacao.valorParcela" disabled />
          </div>
        </div>
        <div class="field col-3 md:col-3">
          <label for="prazoTotal">Prazo</label>
          <InputText
            id="prazoTotal"
            v-model="consignacao.prazoTotal"
            disabled />
        </div>
        <div class="field col-2 md:col-2">
          <label for="prazoTotal">Ano</label>
          <InputText
            id="prazoTotal"
            v-model="consignacao.anoReferencia"
            disabled />
        </div>
        <div class="field col-2 md:col-2">
          <label for="prazoTotal">Mês</label>
          <InputText
            id="prazoTotal"
            v-model="consignacao.mesReferencia"
            disabled />
        </div>
        <div class="field col-2 md:col-2">
          <label for="situacao">Situação</label>
          <InputText id="situacao" v-model="exibirStatusConsignacao" disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="novaConsignacao">Nova Consignação</label>
          <Textarea
            id="novaConsignacao"
            v-model="exibirNovaConsignacao"
            :autoResize="true"
            disabled />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="observacao">Observação</label>
          <Textarea
            id="observacao"
            v-model="consignacao.observacoes"
            :autoResize="true"
            disabled />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import ConsignacaoService from '@/service/ConsignacaoService'
import Consignacao from '@/domain/Consignacao.js'

export default {
  data() {
    return {
      consignacao: new Consignacao(),
      id: this.$route.params.idconsig,
    }
  },

  computed: {
    exibirNovaConsignacao() {
      return this.formatarExibicaoNovaConsignacao()
    },
    exibirStatusConsignacao() {
      return this.exibirNomeFormatadoStatusConsignacao()
    },
  },

  created() {
    this.service = new ConsignacaoService(this.$http)
    this.service
      .getConsignacaoById(this.id)
      .then((res) => {
        this.consignacao = res
      })
      .catch((err) => {
        this.$toast.add({
          severity: 'error',
          summary: err.response.data.message,
          life: 10000,
        })
      })
  },

  methods: {
    formatarData(value) {
      if (!value) return ''
      return new Date(value).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },
    formatarExibicaoNovaConsignacao() {
      return `Contrato: ${
        this.consignacao.consignacaoRenegociacao.numeroContrato
      }. Rubrica: ${
        typeof this.consignacao.consignacaoRenegociacao.rubrica != 'undefined'
          ? this.consignacao.consignacaoRenegociacao.rubrica.rubrica
          : ''
      } | ${
        typeof this.consignacao.consignacaoRenegociacao.rubrica != 'undefined'
          ? this.consignacao.consignacaoRenegociacao.rubrica.nome
          : ''
      }`
    },
    exibirNomeFormatadoStatusConsignacao() {
      if (this.consignacao.statusConsignacao === 'PENDENTE') {
        return 'Pendente'
      } else if (this.consignacao.statusConsignacao === 'ATIVA') {
        return 'Ativa'
      } else if (this.consignacao.statusConsignacao === 'SUSPENSA') {
        return 'Suspensa'
      } else if (this.consignacao.statusConsignacao === 'CANCELADA') {
        return 'Renegociada'
      } else if (this.consignacao.statusConsignacao === 'QUITADA') {
        return 'Quitada'
      } else if (this.consignacao.statusConsignacao === 'JUDICIAL') {
        return 'Judicial'
      } else if (this.consignacao.statusConsignacao === 'QUITACAO') {
        return 'Quitação'
      } else if (
        this.consignacao.statusConsignacao === 'SOLICITADO_CANCELAMENTO'
      ) {
        return 'Renegociada'
      } else if (
        this.consignacao.statusConsignacao === 'SOLICITADA_SUSPENSAO'
      ) {
        return 'Suspensa'
      } else if (this.consignacao.statusConsignacao === 'MOVIMENTO_PARCIAL') {
        return 'Mov. Parcial'
      } else if (
        this.consignacao.statusConsignacao === 'AGUARDANDO_SUPERVIDOR'
      ) {
        return 'Aguardando Supervisor'
      } else if (
        this.consignacao.statusConsignacao === 'NAO_APROVADA_SUPERVISOR'
      ) {
        return 'Não Aprovada Supervisor'
      } else if (this.consignacao.statusConsignacao === 'BAIXADA') {
        return 'Baixada'
      } else if (this.consignacao.statusConsignacao === 'NAO_DESCONTADA') {
        return 'Não Descontada'
      } else if (this.consignacao.statusConsignacao === 'FORA_MARGEM') {
        return 'Fora da Margem'
      }
    },
  },
}
</script>

<style></style>
